<template>
  <div :class="['text-center text-muted vertical-centered', claseAdicional]">
    <font-awesome-icon
      icon="circle-notch"
      class="loader fa-spin"
    />
    <small class="d-block">
      CARGANDO
    </small>
  </div>
</template>

<script>
export default {
  name: 'CargandoVista',
  props: {
    claseAdicional: {
      type: [String, Array],
      default: ''
    }
  }
}
</script>
