<template>
  <mdb-modal
    centered
    elegant
    scrollable
    :show="mostrarFormularioOrden"
    @close="cerrarModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title> {{ titulo }} </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form class="row" @submit.prevent>
        <div
          :class="[
            'col-12',
            { campo: validarValores.fecha && validarValores.fecha.length },
            { valido: validarValores.fecha === 'valido' },
            { invalido: validarValores.fecha === 'invalido' },
          ]"
        >
          <mdb-input
            v-model="orden.fecha.formatted"
            type="date"
            label="Fecha orden"
            outline
            class="my-2"
          />
          <p
            v-if="validarValores.fecha === 'invalido'"
            class="mensaje-invalido date"
          >
            Indica una fecha
          </p>
        </div>
        <div
          :class="[
            'col-12 col-lg-9 pr-md-0',
            'col-12 col-lg-9 pr-md-0',
            { campo: validarValores.monto },
            { valido: validarValores.monto == 'valido' },
            { invalido: validarValores.monto == 'invalido' },
          ]"
        >
          <mdb-input
            v-model.number="orden.monto"
            type="number"
            label="Monto"
            class="my-2 dollar-addon"
            outline
          >
            <span
              slot="prepend"
              class="input-group-text md-addon user-select-none"
              title="Dólares"
            >
              $
            </span>
          </mdb-input>
        </div>
        <div
          :class="[
            'col-12',
            { campo: validarValores.enlace && validarValores.enlace.length },
            { valido: validarValores.enlace === 'valido' },
            { invalido: validarValores.enlace === 'invalido' },
          ]"
        >
          <mdb-input
            v-model.trim="orden.enlace"
            type="url"
            label="Enlace"
            class="my-2"
            outline
            pattern="https://.*"
          />
          <p
            v-if="validarValores.enlace === 'invalido'"
            class="mensaje-invalido"
          >
            Indica el enlace web del producto
          </p>
        </div>
        <div class="col-12 text-center">
          <CargandoVista v-if="cargandoMiniatura" clase-adicional="vc-50px" />
          <div
            v-else-if="!cargandoMiniatura && !orden.miniatura.length"
            class="contenedor-miniatura"
            @click="cargarImagen"
          >
            <font-awesome-icon icon="image" class="icono-miniatura" />
            <small class="text-muted">
              Haz clic aquí para cargar la miniatura
            </small>
          </div>
          <img
            v-else
            :src="orden.miniatura"
            alt="Miniatura del Producto"
            width="50"
            height="50"
            class="cursor-pointer"
            title="Haz click aquí para actualizar la miniatura"
            @click="cargarImagen"
          />
        </div>
        <div class="col-12 text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado"
            class="mt-4"
            @click="enviarOrden"
          >
            Agregar
          </mdb-btn>
        </div>
      </form>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput
} from 'mdbvue'
import CargandoVista from '@/components/CargandoVista.vue'
import { OrdenEstatus } from '@/constantes/ordenes.js'
import ordenMiniaturaGql from '@/graphql/ordenObtenerMiniatura.gql'
import { validarURL } from '@/funciones/funciones.js'
export default {
  name: 'FormularioOrden',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    CargandoVista
  },
  props: {
    mostrarFormularioOrden: {
      type: Boolean,
      required: true
    },
    titulo: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      OrdenEstatus,
      validarValores: {},
      botonDeshabilitado: false,
      cargandoMiniatura: false,
      orden: {
        enlace: '',
        miniatura: '',
        estatus: '',
        fecha: {}
      }
    }
  },
  methods: {
    cerrarModal () {
      this.$emit('cerrar', false)
      this.limpiarCamposForm()
    },
    cargarImagen () {
      if (
        !this.orden.enlace.length ||
        this.orden.enlace.length <= 5 ||
        this.orden.enlace === ''
      ) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Debes completar el campo del enlace',
          tipo: 'error'
        })
      } else if (!validarURL(this.orden.enlace)) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Enlace inválido',
          tipo: 'advertencia'
        })
      }
      this.cargandoMiniatura = true
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: ordenMiniaturaGql,
          variables: {
            enlace: this.orden.enlace
          },
          update: (data) => data.ordenObtenerMiniatura,
          fetchPolicy: 'cache-first'
        })
        .subscribe({
          next: ({ data, loading }) => {
            if (data?.ordenObtenerMiniatura?.miniatura) {
              this.orden.miniatura = data.ordenObtenerMiniatura.miniatura
              this.botonDeshabilitado = loading
              this.cargandoMiniatura = loading
              return
            }
            this.orden.miniatura = ''
            this.botonDeshabilitado = false
            this.cargandoMiniatura = false
            this.$emit('alerta-mensaje', {
              contenido: 'No se pudo obtener la miniatura',
              tipo: 'advertencia'
            })
          },
          error: () => {
            this.$emit('alerta-mensaje', {
              contenido:
                'Ha ocurrido un error obteniendo la miniatura, intenta nuevamente',
              tipo: 'error'
            })
            this.cargandoMiniatura = false
            this.botonDeshabilitado = false
          }
        })
    },
    enviarOrden () {
      this.botonDeshabilitado = true
      this.validarValores = {
        enlace: this.orden.enlace && this.orden.enlace.length >= 5 && validarURL(this.orden.enlace) ? 'valido' : 'invalido',
        fecha: this.orden.fecha.formatted ? 'valido' : 'invalido',
        monto: this.orden.monto ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.$emit('alerta-mensaje', {
          contenido: 'Todos los campos son requeridos',
          tipo: 'error'
        })
        this.botonDeshabilitado = false
        return
      }
      this.botonDeshabilitado = false
      this.orden.estatus = Object.keys(this.OrdenEstatus)[0]
      this.orden.monto = Math.abs(this.orden.monto)
      this.$emit('orden', this.orden)
      this.limpiarCamposForm()
      this.cerrarModal()
    },
    limpiarCamposForm () {
      this.validarValores = {}
      this.orden = {
        enlace: '',
        miniatura: '',
        estatus: '',
        fecha: {}
      }
      this.botonDeshabilitado = false
      this.cargandoMiniatura = false
    }
  }
}
</script>

<style lang="scss" scoped>
.campo {
  margin-bottom: 1rem;
  &.invalido > .mensaje-invalido.prepend {left: 47px;}
}
.contenedor-miniatura {
  text-align: center;

  .icono-miniatura {
    color: $gris-thead-borde;
    cursor: pointer;
    height: 50px;
    width: 50px;
  }
  small {
    cursor: pointer;
    display: block;
    font-style: italic;
    user-select: none;

    @media screen and (min-width: 992px) {
      padding-left: 0.5rem;
    }
  }
}
</style>
