// Expresión regular de un email
const emailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i

// Expresión regular para validar URL
const urlReg = /^(ftp|http|https):\/\/[^ "]+$/

// Filtra array de objetos, por la propiedad que se especifique en el filtro
function multipleFiltrado (array, filtro) {
  const filtrarClaves = Object.keys(filtro)
  return array.filter(
    (item) =>
      !filtrarClaves.every((clave) => filtro[clave].indexOf(item[clave]) !== -1)
  )
}

// Elimina espacios innecesarios y capitaliza la primera letra de las palabras
function capitalizar (string) {
  if (string) {
    const textotoLowerCase = string.toLowerCase()
    const textoCapitalize = textotoLowerCase.replace(/(^\w|\s\w)/g, (m) =>
      m.toUpperCase()
    )
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, ' ')
    string = textoSinEspacios
    return string
  }
}

// Elimina espacios innecesarios y capitaliza solo la primera letra del texto
function capitalizarPrimeraLetra (string) {
  if (string) {
    const textoSinEspacios = string.trim().replace(/\s+/g, ' ')
    string = textoSinEspacios.charAt(0).toUpperCase() + textoSinEspacios.slice(1)
    return string
  }
}

// Avisa si un elemento a ser ingresado a un array de string ya se encuentra agregado
function evitarElementosRepetidos (elemento, listaDeElementos) {
  // Retorna false si no se encuentra el elemento
  // Retorna true si se encuentra el elemento
  const esRepetido = listaDeElementos.includes(elemento)
  return !esRepetido
}

// Avisa si se está cambiando el estatus a uno anterior (Consolidados)
function esRetrocesoEstatus (estatusAnterior, estatusPosterior) {
  const estatusJerarquico = {
    // Estatus disponibles de los consolidados
    Cargando: 1,
    NavieraAerolinea: 2,
    EnTransito: 3,
    EnPuertoAeropuerto: 4,
    ProcesoAduana: 5,
    EsperaCaracas: 6,
    Descargado: 7
  }
  const estatusAnteriorPosicion = estatusJerarquico[estatusAnterior]
  const estatusPosteriorPosicion = estatusJerarquico[estatusPosterior]
  if (estatusAnteriorPosicion > estatusPosteriorPosicion) {
    return true
  }
  return false
}

// Avisa si se está cambiando el estatus a uno anterior (Paquetes)
function paqueteEsRetrocesoEstatus (estatusAnterior, estatusPosterior) {
  const estatusJerarquico = {
    // Estatus disponibles de los paquetes
    EsperaMiami: 1,
    Reempacado: 2,
    Consolidado: 3,
    NavieraAerolinea: 4,
    TransitoInternacional: 5,
    EnPuertoAeropuerto: 6,
    ProcesoAduana: 7,
    EsperaCaracas: 8,
    ListoDespacho: 9,
    TransitoNacional: 10,
    Entregado: 11
  }
  const estatusAnteriorPosicion = estatusJerarquico[estatusAnterior]
  const estatusPosteriorPosicion = estatusJerarquico[estatusPosterior]
  if (estatusAnteriorPosicion > estatusPosteriorPosicion) {
    return true
  }
  return false
}

// Formatea la fecha en un Object con formato {dd-mm, yyyy, dd-mm-yyyy}
function formatearFecha (fecha) {
  const fechaArray = fecha.split('-')
  return {
    ddmm: `${fechaArray[2]}-${fechaArray[1]}`,
    yyyy: fechaArray[0],
    ddmmyyyy: `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`
  }
}

// Valida expresión regular de un teléfono, retorna verdadero si es válido y falso si es invalido
function validarTel (telefono) {
  return !!(parseFloat(telefono) && /^\d{7,12}$/.test(telefono))
}

// Valida expresión regular de un email, retorna verdadero si es válido y falso si es invalido
function validarMail (email) {
  return !!emailReg.test(email)
}

function mostrarSoloDosDecimales (numero) {
  const numeroAString = numero.toString()
  const regex = /(\d*.\d{0,2})/
  return numeroAString.match(regex)[0]
}

// Elimina propiedades vacias de objetos
function eliminarVacios (objeto) {
  for (var clave in objeto) {
    if (typeof objeto[clave] === 'string') {
      if (objeto[clave] === '') {
        delete objeto[clave]
      }
    } else if (typeof objeto[clave] === 'object') {
      eliminarVacios(objeto[clave])
    }
  }
  return objeto
}
/**
 * @param {string} url
 * @returns {Boolean}
 * @description chequea si la url es valida usando una expresion regular
 */
const validarURL = (urlVerificar) => urlReg.test(urlVerificar)

export {
  capitalizar,
  capitalizarPrimeraLetra,
  eliminarVacios,
  emailReg,
  formatearFecha,
  validarTel,
  validarMail,
  multipleFiltrado,
  evitarElementosRepetidos,
  esRetrocesoEstatus,
  mostrarSoloDosDecimales,
  paqueteEsRetrocesoEstatus,
  validarURL
}
