<template>
  <section>
    <div :class="'container-xl'">
      <div class="encabezado-pagina">
        <h2 class="col user-select-none">Compras</h2>
        <div
          v-if="!$apolloData.queries.ordenes.loading"
          class="col-auto"
        >
          <mdb-btn
            flat
            dark-waves
            icon="redo-alt"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 px-3"
            @click="actualizarOrdenes('estatusOrden')"
          >
            Actualizar
          </mdb-btn>
          <mdb-btn
            color="primario"
            icon="plus"
            :disabled="botonDeshabilitado"
            class="my-sm-0 px-3"
            @click="mostrarFormularioOrden = true"
          >
            Nueva Orden
          </mdb-btn>
        </div>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.ordenes.loading"
        clase-adicional="vc-75vh"
      />
      <div v-else class="mb-page">
        <div
          class="row align-items-center justify-content-center justify-content-md-start my-1 mx-0"
        >
          <mdb-btn
            flat
            dark-waves
            icon="list"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 py-2 px-3"
            @click="actualizarOrdenes();
              buscarPor.estatus = '';"
          >
            Todos
          </mdb-btn>
          <div class="col-12 col-sm col-md-auto px-2">
            <div class="md-form md-outline outline-select my-2">
              <select
                id="estatus-select"
                v-model="buscarPor.estatus"
                class="custom-select"
                @change="actualizarOrdenes('estatusOrden')"
              >
                <option class="d-none" disabled value="">Seleccione</option>
                <option
                  v-for="(value, key) in OrdenEstatus"
                  :key="`ordenEstatus-${key}`"
                  :value="key"
                >
                  {{ value }}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
              >
                Estatus
              </label>
            </div>
          </div>
        </div>
        <ul data-table="sticky" class="table">
          <li class="encabezado row">
            <div class="col-9 col-sm-4 col-md-3 pl-4 pl-lg-2">
              <span class="d-sm-none">Detalles</span>
              <span class="d-none d-sm-block">
                Fecha / Estatus
              </span>
            </div>
            <div class="col d-none d-sm-block">Enlace / Miniatura</div>
            <div class="col-auto accion">Acción</div>
          </li>
          <li
            v-for="orden in ordenes"
            :key="`orden-${orden.id}`"
            class="contenido normal row align-items-center"
          >
            <div class="col-9 col-sm-4 col-md-3">
              {{ formatoFecha(orden.fecha.formatted).ddmmyyyy }}
              <small class="d-block py-1">
                {{
                  OrdenEstatus[orden.estatus]
                    ? OrdenEstatus[orden.estatus]
                    : "Sin especificar"
                }}
              </small>
              <div class="d-sm-none">
                <a
                  :href="orden.enlace"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-break"
                >
                  {{ orden.enlace.substring(0, 40) }}...
                </a>
                <img
                  v-if="orden.miniatura && orden.miniatura.length"
                  :src="orden.miniatura"
                  alt="Miniatura del producto"
                  class="miniatura"
                />
                <span v-else class="d-block text-muted user-select-none font-italic">
                  Sin miniatura asignada
                </span>
             <div class="font-weight-bold" title="Monto">${{ orden.monto }}</div>
              </div>
            </div>
            <div class="col d-none d-sm-block">
              <a
                :href="orden.enlace"
                target="_blank"
                rel="noopener noreferrer"
                class="text-break"
              >
                {{ orden.enlace.substring(0, 50) }}...
              </a>
              <img
                v-if="orden.miniatura && orden.miniatura.length"
                :src="orden.miniatura"
                alt="Miniatura del producto"
                class="miniatura"
              />
              <span v-else class="d-block text-muted user-select-none font-italic">
                Sin miniatura asignada
              </span>
             <div class="font-weight-bold" title="Monto">${{ orden.monto }}</div>

            </div>
            <div class="col-auto accion">
              <mdb-btn
                flat
                dark-waves
                :disabled="botonDeshabilitado || estatusDeshabilitado(orden)"
                icon="trash"
                icon-class="texto-peligro"
                class="m-0 ml-2 ml-sm-0 py-3 px-2 btn-bordeadoOnHover"
                title="Eliminar orden"
                @click="mostrarModalConfirmarEliminar = true;
                  ordenIdEliminar = orden.id"
              />
            </div>
          </li>
          <li v-if="!(ordenes && ordenes.length)" class="no-items">
            No hay órdenes de compra registradas
          </li>
        </ul>
      </div>
    </div>
    <FormularioOrden
      :mostrarFormularioOrden="mostrarFormularioOrden"
      titulo="Datos de la nueva orden"
      @cerrar="mostrarFormularioOrden = $event"
      @orden="ordenCrear($event)"
      @alerta-mensaje="alertaMensaje = $event"
    />

    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminar"
      mensaje="Recibimos la petición de eliminar esta orden de compra, es una acción que no puede revertirse"
      :sutantivoFemenino="true"
      @cerrar="mostrarModalConfirmarEliminar = $event;"
      @eliminar="ordenEliminar"
    />

    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import ordenesCompraFiltrosGql from '@/graphql/ordenesCompraFiltros.gql'
import ordenCrearGql from '@/graphql/ordenCrear.gql'
import ordenEliminarGql from '@/graphql/ordenEliminar.gql'
import { OrdenEstatus } from '@/constantes/ordenes.js'
import { formatearFecha } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import CargandoVista from '@/components/CargandoVista.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import FormularioOrden from '@/components/FormularioOrden.vue'
import { leerPersonaId } from '@/utils/datosToken.js'
import { mdbBtn } from 'mdbvue'
export default {
  name: 'Orden',
  components: {
    mdbBtn,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
    FormularioOrden
  },
  data () {
    return {
      buscarPor: { estatus: '' },
      botonDeshabilitado: false,
      mostrarFormularioOrden: false,
      mostrarModalConfirmarEliminar: false,
      ordenes: [],
      ordenIdEliminar: '',
      personaId: '',
      alertaMensaje: { contenido: '' },
      OrdenEstatus
    }
  },
  mounted () {
    this.personaId = leerPersonaId()
  },
  methods: {
    // Dar formato a fecha
    formatoFecha (fecha) {
      return formatearFecha(fecha)
    },
    ordenCrear (orden) {
      delete orden.__typename
      delete orden.fecha.__typename
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: ordenCrearGql,
          variables: {
            orden: orden,
            personaId: this.personaId
          },
          update: (store, { data: { ordenCrear } }) => {
            const data = store.readQuery({
              query: ordenesCompraFiltrosGql,
              variables: {
                filter: {
                  cliente: {
                    id: this.personaId
                  }
                }
              }
            })
            const ordenAgregar = {
              ...orden,
              ...ordenCrear
            }
            ordenAgregar.fecha.__typename = '_Neo4jDateTime'
            data.Orden.push(ordenAgregar)
            store.writeQuery({
              query: ordenesCompraFiltrosGql,
              variables: {
                filter: {
                  cliente: {
                    id: this.personaId
                  }
                }
              },
              data
            })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            ordenCrear: {
              __typename: 'Orden',
              id: -1,
              enlace: orden.enlace,
              monto: orden.monto,
              miniatura: orden.miniatura,
              estatus: orden.estatus,
              fecha: {
                formatted: orden.fecha.formatted,
                __typename: '_Neo4jDateTime'
              }
            }
          }
        })
        .then(() => {
          this.limpiarCamposForm()
          this.alertaMensaje = {
            contenido: 'Agregada orden correctamente',
            tipo: 'correcto'
          }
        })
        .catch(() => {
          this.botonDeshabilitado = true
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error agregando la orden',
            tipo: 'error'
          }
        })
    },
    ordenEliminar () {
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: ordenEliminarGql,
          variables: {
            ordenId: this.ordenIdEliminar
          },
          update: (store, { data: { ordenEliminar: { codigo } } }) => {
            switch (codigo) {
              case 'Correcto':
                this.alertaMensaje = {
                  contenido: 'Eliminado correctamente',
                  tipo: 'correcto'
                }
                break
              case 'Fallido':
                this.alertaMensaje = {
                  contenido: 'No se pudo eliminar la orden',
                  tipo: 'error'
                }
                this.botonDeshabilitado = false
                return
              default:
                this.alertaMensaje = {
                  contenido: 'Ocurrió un error inesperado',
                  tipo: 'error'
                }
                this.botonDeshabilitado = false
                return
            }
            const data = store.readQuery({
              query: ordenesCompraFiltrosGql,
              variables: {
                filter: {
                  cliente: {
                    id: this.personaId
                  }
                }
              }
            })
            data.Orden = data.Orden
              .filter((orden) => orden.id !== this.ordenIdEliminar)
            store.writeQuery({
              query: ordenesCompraFiltrosGql,
              variables: {
                filter: {
                  cliente: {
                    id: this.personaId
                  }
                }
              },
              data
            })
            if (!this.botonDeshabilitado) {
              this.mostrarModalConfirmarEliminar = false
              this.ordenIdEliminar = ''
            }
            this.botonDeshabilitado = false
          },
          optimisticResponse: {
            __typename: 'Mutation',
            ordenEliminar: {
              codigo: 'Correcto',
              __typename: 'Resultado'
            }
          }
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error eliminando la orden',
            tipo: 'error'
          }
        })
    },
    estatusDeshabilitado ({ estatus: estatusOrden }) {
      const estatusEnEspera = Object.keys(OrdenEstatus)[0]
      const estatusIdentificado = Object.keys(OrdenEstatus)[1]
      // eslint-disable-next-line
      return  (estatusOrden === estatusEnEspera || estatusOrden ===  estatusIdentificado) ? false : true 
    },
    actualizarOrdenes (estatus) {
      const filter = {
        cliente: {}
      }
      // eslint-disable-next-line
      estatus && this.buscarPor.estatus !== ''
        ? (filter.estatus = this.buscarPor.estatus)
        : ''
      filter.cliente.id = this.personaId
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: ordenesCompraFiltrosGql,
          update: (data) => data.Orden,
          fetchPolicy: 'cache-and-network',
          variables: {
            filter
          }
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Orden) {
              this.ordenes = data.Orden
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error actualizando las órdenes',
              tipo: 'error'
            }
            this.botonDeshabilitado = false
          }
        })
    },
    limpiarCamposForm () {
      this.botonDeshabilitado = false
      this.mostrarFormularioOrden = false
    }
  },
  apollo: {
    ordenes () {
      return {
        query: ordenesCompraFiltrosGql,
        variables: {
          filter: {
            cliente: {
              id: leerPersonaId()
            }
          }
        },
        update: (data) => data.Orden,
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.miniatura {
  display: block;
  height: 40px;
  margin-top: .2rem;
  white-space: nowrap;
  width: 40px;
}
.accion {width: 74px;}
</style>
