var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-modal',{attrs:{"centered":"","elegant":"","scrollable":"","show":_vm.mostrarFormularioOrden},on:{"close":function($event){return _vm.cerrarModal()}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(" "+_vm._s(_vm.titulo)+" ")])],1),_c('mdb-modal-body',[_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{class:[
          'col-12',
          { campo: _vm.validarValores.fecha && _vm.validarValores.fecha.length },
          { valido: _vm.validarValores.fecha === 'valido' },
          { invalido: _vm.validarValores.fecha === 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"date","label":"Fecha orden","outline":""},model:{value:(_vm.orden.fecha.formatted),callback:function ($$v) {_vm.$set(_vm.orden.fecha, "formatted", $$v)},expression:"orden.fecha.formatted"}}),(_vm.validarValores.fecha === 'invalido')?_c('p',{staticClass:"mensaje-invalido date"},[_vm._v(" Indica una fecha ")]):_vm._e()],1),_c('div',{class:[
          'col-12 col-lg-9 pr-md-0',
          'col-12 col-lg-9 pr-md-0',
          { campo: _vm.validarValores.monto },
          { valido: _vm.validarValores.monto == 'valido' },
          { invalido: _vm.validarValores.monto == 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2 dollar-addon",attrs:{"type":"number","label":"Monto","outline":""},model:{value:(_vm.orden.monto),callback:function ($$v) {_vm.$set(_vm.orden, "monto", _vm._n($$v))},expression:"orden.monto"}},[_c('span',{staticClass:"input-group-text md-addon user-select-none",attrs:{"slot":"prepend","title":"Dólares"},slot:"prepend"},[_vm._v(" $ ")])])],1),_c('div',{class:[
          'col-12',
          { campo: _vm.validarValores.enlace && _vm.validarValores.enlace.length },
          { valido: _vm.validarValores.enlace === 'valido' },
          { invalido: _vm.validarValores.enlace === 'invalido' } ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"url","label":"Enlace","outline":"","pattern":"https://.*"},model:{value:(_vm.orden.enlace),callback:function ($$v) {_vm.$set(_vm.orden, "enlace", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"orden.enlace"}}),(_vm.validarValores.enlace === 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el enlace web del producto ")]):_vm._e()],1),_c('div',{staticClass:"col-12 text-center"},[(_vm.cargandoMiniatura)?_c('CargandoVista',{attrs:{"clase-adicional":"vc-50px"}}):(!_vm.cargandoMiniatura && !_vm.orden.miniatura.length)?_c('div',{staticClass:"contenedor-miniatura",on:{"click":_vm.cargarImagen}},[_c('font-awesome-icon',{staticClass:"icono-miniatura",attrs:{"icon":"image"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Haz clic aquí para cargar la miniatura ")])],1):_c('img',{staticClass:"cursor-pointer",attrs:{"src":_vm.orden.miniatura,"alt":"Miniatura del Producto","width":"50","height":"50","title":"Haz click aquí para actualizar la miniatura"},on:{"click":_vm.cargarImagen}})],1),_c('div',{staticClass:"col-12 text-center"},[_c('mdb-btn',{staticClass:"mt-4",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : 'check',"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":_vm.enviarOrden}},[_vm._v(" Agregar ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }